import { Box, lighten, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { VFC } from "react";
import { ReclaimSku } from "../../reclaim-api/Users";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: lighten(theme.colors.grey, 0.6),
    display: "inline-block",
    borderRadius: 4,
  },
  paid: {
    backgroundColor: theme.colors.warning.main,
  },
  label: {
    padding: theme.spacing(0, 1.25),
    textTransform: "uppercase",
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightBold,
    height: 30,
    lineHeight: "30px"
  },
  small: {
    padding: theme.spacing(0, 0.5),
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightMedium,
    height: 22,
    lineHeight: "22px"
  },
}));

export type EditionBadgeProps = {
  sku: ReclaimSku;
  className?: string;
  size?: "small" | "medium"
};

export const EditionBadge: VFC<EditionBadgeProps> = ({ sku, className, size = "medium" }) => {
  const classes = useStyles();

  return (
    <Box
      component="span"
      className={clsx(classes.root, className, {
        [classes.paid]: sku !== ReclaimSku.Free,
      })}
    >
      <Typography className={clsx(classes.label, {
        [classes.small]: size === "small",
      })} component="span">
        {sku.label}
      </Typography>
    </Box>
  );
};
